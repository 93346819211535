
import {
  Minimize2Icon,
  FilmIcon,
  RepeatIcon,
  LoaderIcon,
  FileTextIcon,
  FileIcon,
} from "vue-feather-icons";
export default {
  name: "NavigationToolList",
  components: {
    Minimize2Icon,
    FilmIcon,
    RepeatIcon,
    LoaderIcon,
    FileTextIcon,
    FileIcon,
  },
  props: {
    tools: {
      required: true,
      default: [],
      type: Array,
    },
  },
  methods: {
    makeURL: (url) => {
      if (url.includes("apng-to-gif") || url.includes("gif-to-apng")) {
        return url.replace("/convert/","/");;
      }
      return url;
    },
  },
};
