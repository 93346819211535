
import { NavigationLinks } from '../../../fc/Constants/config-navigation'
import DropdownCss from '../../../components/ui/DropDownCss'
import NavigationToolList from '~/layouts/partials/navigation/NavigationToolList'
import { truncateEnd } from '~/fc/helpers'

export default {
    name: 'NavigationLinks',
    components: {
        DropdownCss,
        NavigationToolList
    },
    data: () => ({
        dropDownKey: 0
    }),
    computed: {
        NavigationLinks() { return NavigationLinks(this) },
        dashboard() { return this.$route.fullPath.includes('/account') },
        isTab() { return this.$store.state.store.isTab }
    },
    methods: {
        truncate(str, lim) {
            return truncateEnd(str, lim)
        },
        linkClick() {
            this.dropDownKey++
        }
    }
}
